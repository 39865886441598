import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { format, isValid, isAfter } from 'date-fns'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  AggregatorTemplate,
  Typography,
  PinBlockGroup,
  AggregatorPin4,
  TransitionLink,
  ReadMore,
} from '../../components'
import { pipe, map, path, pathOr, take, filter, sortBy } from 'ramda'
import styles from './EducatingPageTemplate.module.css'

const propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        intro: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    courses: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

const getCoursesByCategoryTitle = categoryTitle => {
  return pipe(
    map(a => path(['node'], a)),
    filter(({ frontmatter }) => {
      return pathOr([], ['categories'], frontmatter).includes(categoryTitle)
    }),
    sortBy(({ frontmatter }) => path(['applicationDeadline'], frontmatter)),
    take(4),
  )
}

export const EducatingPageTemplate = ({ data }) => {
  const {
    page,
    categories: { edges: categories },
    courses: { edges: courses },
  } = data
  const {
    frontmatter: { intro },
  } = page

  return (
    <MDXProvider>
      <AggregatorTemplate breadcrumbs={[{ label: 'Educating' }]} intro={intro}>
        <Divider thickness={4} />
        {categories.map((c, i) => {
          const { slug } = c.node.fields
          const { title } = c.node.frontmatter
          const categoryCourses = getCoursesByCategoryTitle(title)(courses)
          const filteredCourseCategories = categoryCourses.filter(
            ({ frontmatter }) =>
              isValid(new Date(frontmatter.startDate)) &&
              isAfter(Date.now(), new Date(frontmatter.startDate)),
          )

          return (
            filteredCourseCategories.length > 0 && (
              <div key={i}>
                <Typography tag="h2" type="boxHeading">
                  {title}
                </Typography>
                <PinBlockGroup columns={2} className={styles.pinBlockGroup}>
                  {filteredCourseCategories.map(
                    ({ frontmatter, fields }, i) => (
                      <AggregatorPin4
                        key={i}
                        title={frontmatter.title}
                        thumbnailSrc={frontmatter.featuredImage}
                        href={fields.slug}
                      >
                        {isValid(new Date(frontmatter.applicationDeadline)) && (
                          <>
                            <strong className="text-teal-light uppercase">
                              Application Deadline
                            </strong>
                            <br />
                            {format(
                              new Date(frontmatter.applicationDeadline),
                              'dd MMMM yyyy',
                            )}
                            <br />
                          </>
                        )}
                        {isValid(new Date(frontmatter.startDate)) && (
                          <>
                            <strong className="text-teal-light uppercase">
                              Course Starts
                            </strong>
                            <br />
                            {format(
                              new Date(frontmatter.startDate),
                              'dd MMMM yyyy',
                            )}
                          </>
                        )}
                      </AggregatorPin4>
                    ),
                  )}
                </PinBlockGroup>
                <ReadMore>
                  <TransitionLink className={styles.readMore} to={slug}>
                    See more
                  </TransitionLink>
                </ReadMore>
                <Divider thickness={4} />
              </div>
            )
          )
        })}
      </AggregatorTemplate>
    </MDXProvider>
  )
}

EducatingPageTemplate.propTypes = propTypes

const EducatingPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <EducatingPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

EducatingPage.propTypes = propTypes

export default EducatingPage

export const pageQuery = graphql`
  query EducatingPageTemplate($slug: String!, $currentDate: Date) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
      }
    }
    categories: allMdx(
      filter: { fields: { contentType: { eq: "courseCategories" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    courses: allMdx(
      filter: {
        fields: { contentType: { eq: "courses" } }
        frontmatter: { applicationDeadline: { gte: $currentDate } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            applicationDeadline
            startDate
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            categories
          }
        }
      }
    }
  }
`
